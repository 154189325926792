export const routes = ["lander-1", "lander-2", "lander-78"];
export const baseUrl = `https://backend.subsidysupportnetwork.org/sites/production?`;

export const ROUTES = {
  congrats: "congrats",
  income: "10k",
  medicareMedicaid: "consolidate",
};

export const sessionStorageRoutes = {
  congrats: "congratsVisited",
  income: "incomeVisited",
  medicareMedicaid: "medicareMedicaidVisited",
  lander: "landerVisited",
};

export const tenk = "10k";
export const consolidate = "consolidate";
export const localScript = "/scripts/localScript.js";
export const scriptValue = "/scripts/scripts.js";
export const apiUrl =
  "https://funnel.improveourcredit.com/ip?key=askdjaslkdjaskjdsla";
